<template>
  <el-dialog
    :visible="show"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :show-close="false"
    :append-to-body="true"
    title="On Demand Report"
  >
    <el-form
      ref="form"
      :model="form"
      :rules="rules"
      label-position="top"
    >
      <el-form-item label="Report Type" prop="type">
        <el-select :value="form.type" @change="typeChangeHandler">
          <el-option
            v-for="option in constants.onDemandReportTypes"
            :key="option.value"
            :label="option.label"
            :value="option.value"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="Dates" prop="dates">
        <!-- using 'key' will force the component to re-render when the type changes -->
        <el-date-picker
          :key="datePickerType"
          v-model="form.dates"
          :type="datePickerType"
          :clearable="false"
          :picker-options="{ firstDayOfWeek: 1 }"
          placeholder="Select"
        />
      </el-form-item>
      <el-form-item label="Recipients" prop="recipients">
        <el-select
          v-model="form.recipients"
          class="recipient-select"
          placeholder="Type to add a recipient"
          multiple
          filterable
          allow-create
          default-first-option
          value-key="email"
          remote
          :remote-method="fetchRooofAccounts"
          :loading="loadingAccounts"
        >
          <el-option
            v-for="item in rooofAccounts"
            :key="item.id"
            :label="item.email"
            :value="item.email"
          />
        </el-select>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="cancel">
        Cancel
      </el-button>
      <el-button
        type="primary"
        @click="validateAndSubmit"
      >
        Send Report
      </el-button>
    </span>
  </el-dialog>
</template>

<script>
import RooofAccountAPI from '@/services/api/accounts'
import { constants } from '@/utils/constants'
import regex from '@/utils/regex'
export default {
  name: 'OnDemandReportsDialog',
  props: {
    show: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      form: {
        type: 'daily',
        dates: '',
        recipients: []
      },
      rules: {
        dates: [
          {
            validator: (rule, value, callback) => {
              if (!value) {
                return callback(new Error('This field is required'))
              }
              if (Array.isArray(value) && !value.length) {
                return callback(new Error('This field is required'))
              }
              return callback()
            },
            trigger: 'blur'
          }
        ],
        recipients: [
          {
            validator: (rule, value, callback) => {
              if (!value.length) {
                return callback(new Error('Please add at least one recipient'))
              }
              for (const email of value) {
                if (!regex.email.test(email)) {
                  return callback(new Error(`${email} is not a valid email address`))
                }
              }
              return callback()
            },
            trigger: 'blur'
          }
        ]
      },
      rooofAccounts: [],
      loadingAccounts: false
    }
  },
  computed: {
    datePickerType () {
      switch (this.form.type) {
        case 'weekly': return 'week'
        case 'monthly': return 'month'
        case 'custom': return 'daterange'
        default: return 'date'
      }
    }
  },
  created () {
    this.constants = constants
  },
  methods: {
    /**
     * Validates and submits the form.
     */
    validateAndSubmit () {
      this.$refs['form'].validate(valid => {
        if (valid) {
          const data = JSON.parse(JSON.stringify(this.form))
          this.$emit('submit', this.formatDates(data))
          this.reset()
        }
      })
    },
    /**
     * Emits an event to close the dialog.
     */
    cancel () {
      this.$emit('close')
      this.reset()
    },
    /**
     * Resets the form fields and validation.
     */
    reset () {
      this.form.dates = ''
      this.$refs['form'].resetFields()
      this.$refs['form'].clearValidate()
    },
    /**
     * Change handler for 'Report Type'.
     * Resets the dates field when the type changes.
     *
     * @param {String} value - report type
     */
    typeChangeHandler (value) {
      this.form.type = value
      this.form.dates = value === 'custom' ? [] : ''
    },
    /**
     * Calls the API to getch a list of rooof accounts that match the query.
     *
     * @param {String} query - search string
     */
    async fetchRooofAccounts (query) {
      if (!query) {
        return
      }
      try {
        this.loadingAccounts = true
        const params = { email__icontains: query }
        const response = await RooofAccountAPI.users.list(params)
        this.rooofAccounts = response
      } catch (err) {
        const details = err.response ? err.response.data : null
        this.$rfAlert.error(this, err.toString(), details)
      } finally {
        this.loadingAccounts = false
      }
    },
    /**
     * Formats the dates to have a start and end date to fulfill request payload.
     * Start date inclusive, End date exclusive
     *
     * @param {Object} data - form data
     * @returns {Object}
     */
    formatDates (data) {
      switch (data.type) {
        case 'weekly':
          // the date picker sets Monday as the start of the week
          data.dates = {
            start: this.$moment(data.dates).subtract(1, 'days').format(),
            end: this.$moment(data.dates).add(6, 'days').format()
          }
          break
        case 'monthly':
          data.dates = {
            start: data.dates,
            end: this.$moment(data.dates).add(1, 'months').format()
          }
          break
        case 'custom':
          data.dates = {
            start: data.dates[0],
            end: this.$moment(data.dates[1]).add(1, 'days').format()
          }
          break
        default:
          data.dates = {
            start: data.dates,
            end: this.$moment(data.dates).add(1, 'days').format()
          }
      }
      return data
    }
  }
}
</script>

<style scoped>
.recipient-select {
  width: 100%;
}
.el-tooltip {
  margin: 0 0.5em;
  font-size: 18px;
  color: #909399;
}
</style>
