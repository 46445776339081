<template>
  <div v-loading="loading" class="group-members">
    <p class="select-label">
      Type to add people
    </p>
    <el-select
      :value="selected"
      placeholder=""
      multiple
      filterable
      value-key="id"
      remote
      :remote-method="fetchOptions"
      @input="updateSelected"
    >
      <el-option
        v-for="item in options"
        :key="item.id"
        :label="formatMember(item)"
        :value="item"
      />
    </el-select>
    <ul class="selected-list">
      <li
        v-for="item in selected"
        :key="item.id"
      >
        {{ formatMember(item) }}
        <el-button
          type="text"
          icon="el-icon-close"
          @click="removeMembership(group.id, item.membershipId)"
        />
      </li>
    </ul>
    <p v-if="inheritedMembers.length" class="select-label">
      Inherited Members
    </p>
    <ul class="selected-list selected-list--inherited">
      <template v-for="item in inheritedMembers">
        <li
          v-for="account in item.members"
          :key="`${item.id}-${account.id}`"
        >
          <span>{{ formatMember(account) }}</span>
          <span>{{ item.name }}</span>
        </li>
      </template>
    </ul>
  </div>
</template>

<script>
import RooofAccountAPI from '@/services/api/accounts'
import { findGroup } from '@/utils/groups'
export default {
  name: 'GroupMembersEdit',
  props: {
    group: {
      required: true,
      type: Object
    },
    groupStructure: {
      type: Object,
      default: null
    },
    api: {
      required: true,
      type: Object
    }
  },
  data () {
    return {
      loading: false,
      selected: [],
      options: [],
      inheritedMembers: []
    }
  },
  watch: {
    group: {
      immediate: true,
      handler () {
        this.setSelectData()
      }
    }
  },
  methods: {
    /**
     * Sets the selected and inherited accounts
     */
    async setSelectData () {
      try {
        this.loading = true
        const response = await this.api.group_memberships.list(this.group.id)
        this.selected = this.options = this.formatMemberships(response)

        // set inherited members
        const memberships = []
        let group = JSON.parse(JSON.stringify(this.group))
        while (group.parent.name) {
          group = findGroup([this.groupStructure], group.parent.id)
          const members = this.formatMemberships(await this.api.group_memberships.list(group.id))
          if (members.length) {
            memberships.push({
              id: group.id,
              name: group.name,
              members: members
            })
          }
        }
        this.inheritedMembers = memberships
      } catch (err) {
        const details = err.response ? err.response.data : null
        this.$rfAlert.error(this, err.toString(), details)
      } finally {
        this.loading = false
      }
    },
    /**
     * Fetches list of rooof accounts from the API based on search query.
     *
     * @param {String} query - input value from search
     */
    async fetchOptions (query) {
      if (!query) {
        return
      }
      try {
        this.loading = true
        const params = { search: query }
        const response = await RooofAccountAPI.users.list(params)
        this.options = response.map(account => {
          return {
            id: account.id,
            email: account.email,
            name: account.name
          }
        })
      } catch (err) {
        const details = err.response ? err.response.data : null
        this.$rfAlert.error(this, err.toString(), details)
      } finally {
        this.loading = false
      }
    },
    /**
     * Event handler for when an account is added or removed.
     *
     * @param {Array} newSelected - array of all the selected accounts
     */
    updateSelected (newSelected) {
      if (newSelected.length > this.selected.length) {
        const added = newSelected.find(account => !this.selected.includes(account))
        this.addMembership(this.group.id, added.id)
      } else if (newSelected.length < this.selected.length) {
        const removed = this.selected.find(account => !newSelected.includes(account))
        this.removeMembership(this.group.id, removed.membershipId)
      }
    },
    /**
     * Calls the API to add a new group membership.
     *
     * @param {Number} groupId - id of group to add account to
     * @param {Number} accountId - id of rooof account
     */
    async addMembership (groupId, accountId) {
      try {
        this.loading = true
        await this.api.group_memberships.create(groupId, { account: accountId })
        this.$emit('refresh')
      } catch (err) {
        this.loading = false
        const details = err.response ? err.response.data : null
        this.$rfAlert.error(this, err.toString(), details)
      }
    },
    /**
     * Calls the API to remove a group membership.
     *
     * @param {Number} groupId - id of group to remove from
     * @param {Number} membershipId
     */
    async removeMembership (groupId, membershipId) {
      try {
        this.loading = true
        await this.api.group_memberships.delete(groupId, membershipId)
        this.$emit('refresh')
      } catch (err) {
        this.loading = false
        const details = err.response ? err.response.data : null
        this.$rfAlert.error(this, err.toString(), details)
      }
    },
    /**
     * Formats membership objects
     *
     * @param {Array} memberships
     */
    formatMemberships (memberships) {
      return memberships.map(membership => {
        return {
          id: membership.account.id,
          email: membership.account.email,
          name: membership.account.name,
          membershipId: membership.id
        }
      })
    },
    /**
     * Formats members based on if they have a name/email or not.
     *
     * @param {Object} account
     * @returns {String}
     */
    formatMember (account) {
      if (account.name && account.email) {
        return `${account.name} (${account.email})`
      }

      return account.name || account.email
    }
  }
}
</script>

<style lang="scss" scoped>
.group-members {
  .select-label {
    font-size: 14px;
    margin: 0.5em 0;
  }
  .el-select {
    width: 100%;
    ::v-deep .el-tag {
      display: none;
    }
  }
  .selected-list {
    margin-top: 1em;
    padding: 0;
    li {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #ebeef5;
      padding: 0 0.75em;
      height: 40px;
      box-sizing: border-box;

      &:first-child {
        border-top: 1px solid #ebeef5;
      }

      .el-button {
        margin-left: auto;
        padding: 0;
      }
    }

    &.selected-list--inherited {
      margin-top: 0;
      li {
        color: #c0c4cc;
        :last-child {
          font-size: 0.75rem;
          font-weight: bold;
        }
      }
    }
  }
}
</style>
