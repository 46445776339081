<template>
  <div v-loading="loading">
    <template v-if="isReporting">
      <h1 class="section-header">
        On-demand Reports
      </h1>
      <el-button @click="showOnDemandReportsDialog = true">
        Send an On-demand Report
      </el-button>
      <on-demand-reports-dialog
        :show="showOnDemandReportsDialog"
        @close="showOnDemandReportsDialog = false"
        @submit="submitOnDemandReport"
      />
      <h1 class="section-header">
        Report Recipients
      </h1>
      <el-input
        v-model="search"
        placeholder="Search"
        clearable
      />
    </template>
    <template v-else>
      <p class="select-label">
        Type to add people
      </p>
      <el-select
        :value="recipients"
        placeholder=""
        multiple
        filterable
        value-key="account.id"
        remote
        :remote-method="fetchOptions"
        @input="updateRecipients"
      >
        <el-option
          v-for="item in options"
          :key="item.account.id"
          :label="formatMember(item.account)"
          :value="item"
        />
      </el-select>
    </template>
    <data-table :data="filteredRecipients">
      <el-table-column
        :label="isReporting ? 'Group Member' : 'Recipient'"
        prop="account.email"
        sortable
      >
        <template slot-scope="scope">
          <div :class="{ 'no-reports' : !hasReports(scope.row) }">
            {{ formatMember(scope.row.account) }}
          </div>
        </template>
      </el-table-column>

      <el-table-column
        v-for="(report, index) in reports"
        :key="index"
        :label="report.label"
        :prop="report.value"
        width="110"
        align="center"
        sortable
      >
        <template slot-scope="scope">
          <el-checkbox
            :value="scope.row[report.value]"
            @change="recipientChange($event, scope)"
          />
        </template>
      </el-table-column>

      <el-table-column
        v-if="!isReporting"
        width="50"
        align="center"
      >
        <template slot-scope="scope">
          <el-button
            type="text"
            icon="el-icon-close"
            @click="removeMembership(group.id, scope.row.id)"
          />
        </template>
      </el-table-column>
    </data-table>
    <template v-if="isReporting">
      <h1 class="section-header">
        Report Settings
      </h1>
      <p class="select-label">
        Sort by:
      </p>
      <el-select :value="group.sorting" @change="updateReportSorting">
        <el-option
          v-for="option in constants.groupReportSorting"
          :key="option.value"
          :label="option.label"
          :value="option.value"
        />
      </el-select>
    </template>
  </div>
</template>
<script>
import moment from 'moment-timezone'
import DataTable from '@/components/tables/DataTable'
import OnDemandReportsDialog from './OnDemandReportsDialog'
import RooofAccountAPI from '@/services/api/accounts'
import ReportingAPI from '@/services/api/reporting'
import { constants } from '@/utils/constants'
export default {
  name: 'GroupReportRecipientsEdit',
  components: {
    'data-table': DataTable,
    'on-demand-reports-dialog': OnDemandReportsDialog
  },
  props: {
    group: {
      required: true,
      type: Object
    },
    groupStructure: {
      type: Object,
      default: null
    },
    api: {
      required: true,
      type: Object
    },
    isReporting: {
      required: true,
      type: Boolean
    }
  },
  data () {
    return {
      loading: false,
      search: '',
      recipients: [],
      options: [],
      showOnDemandReportsDialog: false
    }
  },
  computed: {
    filteredRecipients () {
      const filtered = this.recipients.filter(recipient =>
        (recipient.account.name &&
        recipient.account.name.includes(this.search)) ||
        (recipient.account.email &&
        recipient.account.email.includes(this.search)))
      return this.sortMemberships(filtered)
    },
    reports () {
      return this.isReporting ? constants.emailReports : constants.invoiceSummaries
    }
  },
  watch: {
    group: {
      immediate: true,
      handler () {
        this.getRecipients()
      }
    }
  },
  created () {
    this.constants = constants
  },
  methods: {
    /**
     * Fetches the group memberships from the API.
     */
    async getRecipients () {
      try {
        this.loading = true
        const response = await this.api.group_memberships.list(this.group.id)
        this.recipients = this.options = response
      } catch (err) {
        const details = err.response ? err.response.data : null
        this.$rfAlert.error(this, err.toString(), details)
      } finally {
        this.loading = false
      }
    },
    /**
     * Change handler when a report frequency has been changed using the checkboxes.
     * Calls the API to update a membership.
     *
     * @param {Boolean} value - whether the checkbox is checked or unchecked
     * @param {Object} scope - object that contains the field name and membership id
     */
    async recipientChange (value, scope) {
      const membershipId = scope.row.id
      const data = { [scope.column.property]: value }
      try {
        this.loading = true
        await this.api.group_memberships.partialUpdate(this.group.id, membershipId, data)
        this.$emit('refresh')
      } catch (err) {
        this.loading = false
        const details = err.response ? err.response.data : null
        this.$rfAlert.error(this, err.toString(), details)
      }
    },
    /**
     * Returns true if a membership receives one or more reports.
     *
     * @param {Object} membership
     * @returns {Boolean}
     */
    hasReports (membership) {
      const reportFields = this.reports.map(report => report.value)
      for (const field of reportFields) {
        if (membership[field]) return true
      }
      return false
    },
    /**
     * Sorts memberships by ones that are receiving reports, then alphabetically by email.
     *
     * @param {Array} memberships - Array of membership objects
     */
    sortMemberships (memberships) {
      return memberships.sort((a, b) => {
        if (this.hasReports(a) && !this.hasReports(b)) {
          return -1
        } else if (!this.hasReports(a) && this.hasReports(b)) {
          return 1
        } else {
          if (a.account.email < b.account.email) {
            return -1
          } else if (a.account.email > b.account.email) {
            return 1
          }
          return 0
        }
      })
    },
    /**
     * Fetches list of rooof accounts from the API based on search query.
     *
     * @param {String} query - input value from search
     */
    async fetchOptions (query) {
      if (!query) {
        return
      }
      try {
        this.loading = true
        const params = { search: query }
        const response = await RooofAccountAPI.users.list(params)
        this.options = response.map(account => {
          return {
            account: {
              id: account.id,
              email: account.email,
              name: account.name
            }
          }
        })
      } catch (err) {
        const details = err.response ? err.response.data : null
        this.$rfAlert.error(this, err.toString(), details)
      } finally {
        this.loading = false
      }
    },
    /**
     * Event handler for when an account is added or removed.
     *
     * @param {Array} newRecipients - array of all recipients
     */
    updateRecipients (newRecipients) {
      if (newRecipients.length > this.recipients.length) {
        const added = newRecipients.find(account => !this.recipients.includes(account))
        this.addMembership(this.group.id, added.account.id)
      } else {
        const removed = this.recipients.find(account => !newRecipients.includes(account))
        this.removeMembership(this.group.id, removed.id)
      }
    },
    /**
     * Calls the API to add a new group membership.
     *
     * @param {Number} groupId - id of group to add account to
     * @param {Number} accountId - id of rooof account
     */
    async addMembership (groupId, accountId) {
      try {
        this.loading = true
        await this.api.group_memberships.create(groupId, { account: accountId })
        this.$emit('refresh')
      } catch (err) {
        this.loading = false
        const details = err.response ? err.response.data : null
        this.$rfAlert.error(this, err.toString(), details)
      }
    },
    /**
     * Calls the API to remove a group membership.
     *
     * @param {Number} groupId - id of group to remove from
     * @param {Number} membershipId
     */
    async removeMembership (groupId, membershipId) {
      try {
        this.loading = true
        await this.api.group_memberships.delete(groupId, membershipId)
        this.$emit('refresh')
      } catch (err) {
        this.loading = false
        const details = err.response ? err.response.data : null
        this.$rfAlert.error(this, err.toString(), details)
      }
    },
    /**
     * Calls the API to update the group's report sorting.
     *
     * @param {String} value - sorting value from select change
     */
    async updateReportSorting (value) {
      try {
        this.loading = true
        await this.api.groups.partialUpdate(this.group.id, { sorting: value })
        this.$emit('refresh')
      } catch (err) {
        this.loading = false
        const details = err.response ? err.response.data : null
        this.$rfAlert.error(this, err.toString(), details)
      }
    },
    /**
     * Converts date to date in Pacific time zone
     * Ignores time
     *
     * @param date - date to be converted to PST
     */
    convertToPacific (date) {
      const onlyDate = moment(date).format('YYYY-MM-DD')
      const pacificDate = moment.tz(onlyDate, 'America/Vancouver').startOf('day').toISOString()
      return pacificDate
    },
    /**
     * Sends post request to API to send out an on demand report.
     * Dates are in America/Vancouver time to match with server (ADM-718)
     *
     * @params {Object} form - form data from dialog
     */
    async submitOnDemandReport (form) {
      try {
        this.loading = true
        const payload = {
          report_type: form.type === 'weekly' ? 'week' : 'syndication',
          group: this.group.id,
          period: form.type === 'custom' ? 'monthly' : form.type,
          start: this.convertToPacific(form.dates.start),
          end: this.convertToPacific(form.dates.end),
          recipients: form.recipients
        }
        await ReportingAPI.on_demand.create(payload)
        this.$message({
          message: 'Success! Recipients should receive an email shortly.',
          type: 'success',
          duration: 3500,
          customClass: 'e2e-on-demand-report-msg'
        })
      } catch (err) {
        const details = err.response ? err.response.data : null
        this.$rfAlert.error(this, err.toString(), details)
      } finally {
        this.loading = false
        this.showOnDemandReportsDialog = false
      }
    },
    /**
     * Formats members based on if they have a name/email or not.
     *
     * @param {Object} account
     * @returns {String}
     */
    formatMember (account) {
      if (account.name && account.email) {
        return `${account.name} (${account.email})`
      }

      return account.name || account.email
    }
  }
}
</script>

<style lang="scss" scoped>
.select-label {
  font-size: 14px;
  margin: 0.5em 0;
}
.el-select {
  width: 100%;
  ::v-deep .el-tag {
    display: none;
  }
}
.no-reports {
  color: #c0c4cc;
}
.section-header {
  margin-top: 2em;
  &:first-of-type {
    margin-top: 1em;
  }
}
</style>
