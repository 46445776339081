<template>
  <drawer
    :visible="visible"
    :show-close="false"
    :size="drawerSize"
    @update:visible="$emit('update:visible', $event)"
  >
    <template #title>
      <el-button
        v-if="!isReporting"
        type="text"
        icon="el-icon-back"
        @click="$emit('close')"
      />
      <div>
        <div class="drawer-title">
          <h1>
            {{ group.name }}
          </h1>
          <el-button
            v-if="group.parent"
            class="group-delete-btn"
            type="text"
            icon="el-icon-delete"
            @click="$emit('delete', group)"
          />
        </div>
        <p v-if="group.activation_code" class="el-drawer__subtitle">
          {{ group.activation_code }}
        </p>
        <p v-if="group.description" class="el-drawer__subtitle">
          {{ group.description }}
        </p>
      </div>
    </template>

    <el-tabs v-model="activeTab" :class="{ 'el-tabs--invoicing' : !isReporting }">
      <el-tab-pane
        v-for="tab in tabs"
        :key="tab.name"
        :label="tab.label"
        :name="tab.name"
      />
      <keep-alive>
        <component
          :is="activeTabComponent"
          :group="group"
          :group-structure="groupStructure"
          :api="api"
          :is-reporting="isReporting"
          @refresh="$emit('refresh')"
        />
      </keep-alive>
    </el-tabs>
  </drawer>
</template>

<script>
import Drawer from '@/components/drawers/Drawer'
import GroupMembersEdit from './GroupMembersEdit'
import GroupPropertiesEdit from './GroupPropertiesEdit'
import GroupSettingsEdit from './GroupSettingsEdit'
import GroupReportRecipientsEdit from './GroupReportRecipientsEdit'
import CraigslistAPI from '@/services/api/craigslist'
import InvoicesAPI from '@/services/api/invoices'
import { enums } from '@/utils/constants'

export default {
  name: 'GroupEdit',
  components: {
    'drawer': Drawer
  },
  props: {
    group: {
      required: true,
      type: Object
    },
    groupStructure: {
      type: Object,
      default: null
    },
    type: {
      required: true,
      type: String
    },
    visible: {
      required: true,
      type: Boolean
    }
  },
  data () {
    return {
      activeTab: this.group.parent ? 'properties' : 'recipients'
    }
  },
  computed: {
    activeTabComponent () {
      return this.tabs.find(tab => tab.name === this.activeTab).component
    },
    api () {
      return this.type === enums.groupTypes.REPORTING ? CraigslistAPI : InvoicesAPI
    },
    isReporting () {
      return this.type === enums.groupTypes.REPORTING
    },
    drawerSize () {
      return this.isReporting ? '40%' : 'calc(100% - 64px)'
    },
    tabs () {
      return this.isReporting
        ? [
          { label: 'Properties', name: 'properties', component: GroupPropertiesEdit },
          { label: 'Group Members', name: 'members', component: GroupMembersEdit },
          { label: 'Email Reports', name: 'recipients', component: GroupReportRecipientsEdit },
          { label: 'Group Settings', name: 'settings', component: GroupSettingsEdit }
        ]
        : this.invoicingTabs
    },
    invoicingTabs () {
      if (this.group.parent) {
        return [
          { label: 'Properties', name: 'properties', component: GroupPropertiesEdit },
          { label: 'Invoice Recipients', name: 'recipients', component: GroupReportRecipientsEdit },
          { label: 'Group Settings', name: 'settings', component: GroupSettingsEdit }
        ]
      }
      return [
        { label: 'Invoice Recipients', name: 'recipients', component: GroupReportRecipientsEdit },
        { label: 'Group Settings', name: 'settings', component: GroupSettingsEdit }
      ]
    }
  },
  watch: {
    visible (value) {
      // reset active tab upon drawer open
      if (value) {
        this.activeTab = this.group.parent ? 'properties' : 'recipients'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.el-drawer__wrapper {
  ::v-deep .el-drawer  {
    .el-drawer__header {
      display: flex;
      .el-button {
        flex: unset;
        font-size: 32px;
        margin-right: 0.5em;
        padding: 0;
      }
      .drawer-title {
        display: flex;

        h1 {
          margin: 0;
        }
        .group-delete-btn {
          padding: 0;
          margin-left: 0.5em;
          font-size: 18px;
        }
      }
    }
    .el-drawer__body {
      overflow: auto;
      .el-tabs--invoicing {
        padding-left: 50px;
      }
    }
  }
}
</style>
