<template>
  <el-select
    v-model="selected"
    :remote-method="filterRecurringInvoices"
    :loading="loading"
    placeholder="Type to search"
    filterable
    clearable
    remote
    @input="handleSelect"
  >
    <el-option
      v-for="recurring_invoice in options"
      :key="recurring_invoice.recurring_invoice_id"
      :label="recurring_invoice.recurring_invoice_id"
      :value="recurring_invoice.recurring_invoice_id"
    >
      <span>
        {{ getRecurringInvoiceLabel(recurring_invoice) }}
      </span>
    </el-option>
  </el-select>
</template>

<script>
import InvoicesAPI from '@/services/api/invoices'

export default {
  name: 'RecurringInvoiceDropdown',
  props: {
    value: {
      type: String,
      required: false,
      default: ''
    }
  },
  data () {
    return {
      selected: '',
      query: '',
      options: [],
      loading: false
    }
  },
  created () {
    this.selected = this.value
  },
  methods: {
    /**
     * Passes selection to parent
     * @param {String} [input]
     */
    handleSelect (input) {
      this.$emit('input', input)
    },
    /**
     * Searches the database for Recurring Invoices that match the users query
     * @param {String} [query] - Users search query
     */
    async filterRecurringInvoices (query) {
      this.query = query
      if (!query) {
        this.options = []
        return
      }
      this.loading = true
      const params = {
        recurring_invoice_id: query
      }
      this.options = await InvoicesAPI.recurring_invoices.list(params)
      this.loading = false
    },
    /**
     * Creates a label for a given Recurring Invoice object
     * @param {Object} [recurringInvoice]
     */
    getRecurringInvoiceLabel (recurringInvoice) {
      return `${recurringInvoice.recurring_invoice_id} - ${recurringInvoice.customer_name}`
    }
  }
}
</script>
