<template>
  <el-form
    ref="form"
    v-loading="loading"
    :model="form"
    :rules="rules"
    label-position="top"
    @submit.native.prevent
  >
    <el-form-item label="Name" prop="name">
      <el-input v-model="form.name" :disabled="!group.parent" />
    </el-form-item>
    <el-form-item label="Description" prop="description">
      <el-input
        v-model="form.description"
        type="textarea"
        :rows="3"
        :disabled="!group.parent"
      />
    </el-form-item>
    <div v-if="group.contains_all_properties" class="all-properties">
      <i class="el-icon-star-on" />
      This group will always contain all properties in the company
    </div>
    <el-form-item
      v-else-if="!isTopLevelGroup"
      label="Parent Group"
      prop="parent"
      class="is-required"
    >
      <el-select v-model="form.parent" value-key="id">
        <el-option
          v-for="option in parentOptions"
          :key="option.id"
          :label="option.name"
          :value="option"
        />
      </el-select>
    </el-form-item>
    <template v-if="!isReporting">
      <el-form-item label="Zoho Customer ID" prop="zoho_customer_id">
        <el-input v-model="form.zoho_customer_id" />
      </el-form-item>
      <el-form-item label="Zoho Recurring Invoice ID" prop="zoho_recurring_invoice_id">
        <recurring-invoice-dropdown v-model="form.zoho_recurring_invoice_id" />
      </el-form-item>
    </template>
    <el-form-item>
      <el-button
        type="primary"
        :disabled="!formChanged"
        @click="validateAndSubmit"
      >
        Save
      </el-button>
      <el-button :disabled="!formChanged" @click="setFormData">
        Cancel
      </el-button>
    </el-form-item>
  </el-form>
</template>

<script>
import RecurringInvoiceDropdown from '@/components/forms/RecurringInvoiceDropdown'
export default {
  name: 'GroupSettingsEdit',
  components: { RecurringInvoiceDropdown },
  props: {
    group: {
      required: true,
      type: Object
    },
    groupStructure: {
      type: Object,
      default: null
    },
    api: {
      required: true,
      type: Object
    },
    isReporting: {
      required: true,
      type: Boolean
    }
  },
  data () {
    return {
      loading: false,
      originalForm: {},
      form: {
        name: '',
        description: '',
        parent: undefined
      },
      rules: {
        name: [
          { required: true, message: 'This field is required', trigger: 'blur' },
          { max: 128, message: 'Name cannot be more than 128 characters', trigger: 'blur' }
        ],
        parent: [
          { required: true, message: 'This field is required', trigger: 'blur' }
        ],
        zoho_customer_id: [
          { max: 18, message: 'This field cannot be more than 18 characters', trigger: 'blur' }
        ],
        zoho_recurring_invoice_id: [
          { max: 18, message: 'This field cannot be more than 18 characters', trigger: 'blur' }
        ]
      },
      parentOptions: []
    }
  },
  computed: {
    // "Top Level Group" here refers to the group directly below the nameless phantom group
    isTopLevelGroup () {
      return !this.group.parent || !this.group.parent.name
    },
    topLevelGroup () {
      return this.groupStructure || this.group
    },
    formChanged () {
      return JSON.stringify(this.originalForm) !== JSON.stringify(this.form)
    }
  },
  watch: {
    group: {
      immediate: true,
      handler () {
        this.setFormData()
      }
    }
  },
  methods: {
    /**
     * Initializes form fields with group values.
     */
    async setFormData () {
      try {
        this.loading = true
        this.originalForm = JSON.parse(JSON.stringify(this.group))
        this.form = JSON.parse(JSON.stringify(this.originalForm))
        this.parentOptions = this.getParentOptions(this.topLevelGroup, this.group.id)
      } catch (err) {
        const details = err.response ? err.response.data : null
        this.$rfAlert.error(this, err.toString(), details)
      } finally {
        this.loading = false
      }
    },
    /**
     * Recursive function that returns all groups within the structure
     * excluding the group we are searching for and its children.
     *
     * @param {Object} group - group structure to traverse
     * @param {Number} groupId - group to search
     * @returns {Array}
     */
    getParentOptions (group, groupId) {
      if (group.id === groupId) {
        return []
      }
      const options = [{
        id: group.id,
        name: group.name
      }]
      for (const child of group.children) {
        options.push(...this.getParentOptions(child, groupId))
      }
      return options
    },
    /**
     * Form submit handler
     */
    validateAndSubmit () {
      this.$refs['form'].validate(valid => {
        if (valid) {
          const data = JSON.parse(JSON.stringify(this.form))
          if (data.parent) {
            data.parent = data.parent.id // overwrite with pkey
          } else {
            // this is a nameless phantom group
            delete data.name
            delete data.description
            delete data.parent
          }
          this.updateGroup(this.group.id, data)
        }
      })
    },
    /**
     * Calls API to update a Group.
     */
    async updateGroup (groupId, data) {
      try {
        this.loading = true
        await this.api.groups.update(groupId, data)
        this.$message({
          message: 'Group successfully updated.',
          type: 'success',
          duration: 3500
        })
        this.$emit('refresh')
      } catch (err) {
        this.loading = false
        const details = err.response ? err.response.data : null
        this.$rfAlert.error(this, err.toString(), details)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.el-select {
  width: 100%;
}
.all-properties {
  margin: 1.5em 0;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  i {
    font-size: 20px;
    margin-right: 0.25em;
  }
}
</style>
