<template>
  <el-dialog
    class="group-delete-dialog"
    :visible="show"
    :title="`Delete ${group.name}`"
    @close="$emit('close')"
  >
    <div v-if="group.parent && !group.parent.name">
      Are you sure you want to delete <b>{{ group.name }}</b>?
      This action cannot be undone.
    </div>
    <div v-else>
      <b>{{ group.name }}</b> contain properties or other subgroups.
      Any properties or subgroups will be moved to <b>{{ group.parent ? group.parent.name : '' }}</b>.
      Would you like to continue?
    </div>
    <span slot="footer">
      <el-button @click="$emit('close')">
        Cancel
      </el-button>
      <el-button type="danger" @click="$emit('delete')">
        Delete
      </el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      required: true
    },
    group: {
      type: Object,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
.group-delete-dialog {
  ::v-deep .el-dialog__body {
    word-break: break-word;
    line-height: 1.5em;
  }
}
</style>
