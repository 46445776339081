<template>
  <div
    v-loading="loading"
    class="group-properties"
    :class="{ 'group-properties--invoicing' : !isReporting }"
  >
    <p class="select-label">
      Type to add a property {{ isReporting ? 'or subgroup' : '' }}
    </p>
    <el-select
      ref="select"
      :value="selected"
      placeholder=""
      multiple
      filterable
      value-key="id"
      remote
      :remote-method="fetchOptions"
      @input="updateSelected"
    >
      <template #empty>
        <div class="empty">
          <li v-if="isReporting" class="select-action">
            <el-button
              type="text"
              icon="el-icon-plus"
              @click="addSubGroupHandler"
            >
              Add Sub Group
            </el-button>
          </li>
          <p class="el-select-dropdown__empty">
            {{ loading ? 'Loading...' : 'No Data' }}
          </p>
        </div>
      </template>
      <li v-if="isReporting" class="select-action">
        <el-button
          type="text"
          icon="el-icon-plus"
          @click="addSubGroupHandler"
        >
          Add Sub Group
        </el-button>
      </li>
      <el-option
        v-for="item in options"
        :key="item.id"
        :label="item.name"
        :value="item"
        :disabled="topLevelGroup.contains_all_properties && !isSubgroup(item) && selected.some(obj => obj.id === item.id)"
      >
        <i v-if="isSubgroup(item)" class="el-icon-folder option-icon" />
        {{ item.name }}
      </el-option>
    </el-select>
    <ul class="selected-list">
      <transition name="slide-fade">
        <div v-if="showDeleteConfirmation" class="drawer-confirmation">
          <p>
            <b>{{ subgroupToDelete.name }}</b> contain properties or other subgroups.
            Any properties or subgroups will be moved to <b>{{ group.name }}</b>.
            Would you like to continue?
          </p>
          <div class="btn-group">
            <el-button
              type="danger"
              size="mini"
              @click="deleteSubgroup(subgroupToDelete)"
            >
              Delete
            </el-button>
            <el-button size="mini" @click="showDeleteConfirmation = false">
              Cancel
            </el-button>
          </div>
        </div>
      </transition>
      <transition name="slide-fade">
        <div v-if="showPropertyAddConfirmation" class="drawer-confirmation">
          <p>
            Property <b>{{ propertyToAdd.property.name }}</b> already exists in
            group <b>{{ propertyToAdd.group.name }}</b>.
            Would you like to move this property?
          </p>
          <div class="btn-group">
            <el-button
              type="danger"
              size="mini"
              @click="addProperty(propertyToAdd.property)"
            >
              Move
            </el-button>
            <el-button size="mini" @click="showPropertyAddConfirmation = false">
              Cancel
            </el-button>
          </div>
        </div>
      </transition>
      <li v-show="showNewGroupInput" class="new-subgroup">
        <i class="el-icon-folder" />
        <el-input
          ref="newGroupInput"
          v-model="newGroupName"
          size="small"
          placeholder="Sub Group Name"
          @blur="createSubgroup"
          @keyup.enter.native="$event.target.blur()"
        />
      </li>
      <li
        v-for="item in selected"
        :key="item.id"
      >
        <i v-if="isSubgroup(item)" class="el-icon-folder" />
        <span class="list-item-label">
          {{ item.name }}
        </span>
        <el-button
          v-if="!topLevelGroup.contains_all_properties || isSubgroup(item)"
          type="text"
          icon="el-icon-close"
          @click="isSubgroup(item) ? deleteSubgroupHandler(item) : removeProperty(item)"
        />
      </li>
    </ul>
  </div>
</template>

<script>
import RooofAPI from '@/services/api/rooof'
import { findGroup, findGroupWithProperty, getSubgroups } from '@/utils/groups'
export default {
  name: 'GroupPropertiesEdit',
  props: {
    group: {
      required: true,
      type: Object
    },
    groupStructure: {
      type: Object,
      default: null
    },
    api: {
      required: true,
      type: Object
    },
    isReporting: {
      required: true,
      type: Boolean
    }
  },
  data () {
    return {
      selected: [],
      options: [],
      loading: false,
      newGroupName: '',
      showNewGroupInput: false,
      showDeleteConfirmation: false,
      subgroupToDelete: null,
      propertyToAdd: null,
      showPropertyAddConfirmation: false
    }
  },
  computed: {
    topLevelGroup () {
      return this.groupStructure || this.group
    }
  },
  watch: {
    group: {
      immediate: true,
      handler () {
        this.setSelectData()
      }
    }
  },
  methods: {
    /**
     * Sets the selected properties and subgroups
     * and pre-fills the dropdown options with them.
     */
    setSelectData () {
      const group = findGroup([this.topLevelGroup], this.group.id)
      this.selected = this.options = this.isReporting ? [...group.properties, ...group.children]
        : group.properties
      this.loading = false
    },
    /**
     * Fetches all properties for the company and all subgroups
     * nested inside the top level group and sets these as the select options.
     *
     * @param {String} query - input value from search
     */
    async fetchOptions (query) {
      if (!query) {
        return
      }
      try {
        this.loading = true
        // get list of properties for the company
        const response = await RooofAPI.companies.propertySummary(this.group.company.id, { q: query })
        const properties = response.map(property => {
          return {
            id: property.id,
            name: property.name
          }
        })
        if (this.isReporting) {
          // get list of existing subgroups nested in the top level group
          const subgroups = getSubgroups(this.topLevelGroup).filter(
            subgroup => subgroup.name.toLowerCase().includes(query.toLowerCase()) &&
            subgroup.id !== this.group.parent.id &&
            subgroup.id !== this.group.id
          )
          this.options = [...properties, ...subgroups]
        } else {
          this.options = properties
        }
      } catch (err) {
        const details = err.response ? err.response.data : null
        this.$rfAlert.error(this, err.toString(), details)
      } finally {
        this.loading = false
      }
    },
    /**
     * Event handler for when a property or subgroup is added or removed.
     *
     * @param {Array} newSelected - array of all the selected properties and subgroups
     */
    updateSelected (newSelected) {
      // check if an item was added or removed
      if (newSelected.length > this.selected.length) {
        const added = newSelected.find(property => !this.selected.includes(property))
        if (this.isSubgroup(added)) {
          this.addSubgroup(added, this.group.id)
        } else {
          this.addPropertyHandler(added)
        }
      } else {
        const removed = this.selected.find(property => !newSelected.includes(property))
        if (this.isSubgroup(removed)) {
          this.deleteSubgroupHandler(removed)
        } else {
          this.removeProperty(removed)
        }
      }
    },
    /**
     * Handles the removal of properties from other groups before adding,
     * and displaying a warning dialog for invoicing groups.
     *
     * @param {Object} propertyAdded - property object
     */
    async addPropertyHandler (propertyAdded) {
      try {
        this.loading = true
        if (this.isReporting) {
          // check if we need to remove the property from another group (in the same structure)
          const groupWithProperty = findGroupWithProperty([this.topLevelGroup], propertyAdded.id)
          if (groupWithProperty) {
            // add property to new group
            const properties = [...findGroup([this.topLevelGroup], this.group.id).properties, propertyAdded]
            await this.api.groups.partialUpdate(this.group.id, { properties: properties })
            // remove property from old group
            const newProperties = groupWithProperty.properties.filter(property => property.id !== propertyAdded.id)
            await this.api.groups.partialUpdate(groupWithProperty.id, { properties: newProperties })
            this.$emit('refresh')
            return
          }
        } else {
          // display warning if property already belongs to another non top level group
          const property = await RooofAPI.properties.retrieve(propertyAdded.id)
          if (property.invoicing_group) {
            const group = await this.api.groups.retrieve(property.invoicing_group)
            if (group && group.parent) {
              this.propertyToAdd = {
                property: { id: property.id, name: property.name },
                group: { id: group.id, name: group.name }
              }
              this.$refs.select.blur()
              this.loading = false
              this.showPropertyAddConfirmation = true
              return
            }
          }
        }
        this.addProperty(propertyAdded)
      } catch (err) {
        const details = err.response ? err.response.data : null
        this.$rfAlert.error(this, err.toString(), details)
      }
    },
    /**
     * Sends request to API to add a property to the group.
     *
     * @param {Object} propertyAdded - property object
     */
    async addProperty (propertyAdded) {
      try {
        this.loading = true
        const properties = [...findGroup([this.topLevelGroup], this.group.id).properties, propertyAdded]
        await this.api.groups.partialUpdate(this.group.id, { properties: properties })
        this.$emit('refresh')
      } catch (err) {
        this.loading = false
        const details = err.response ? err.response.data : null
        this.$rfAlert.error(this, err.toString(), details)
      } finally {
        this.showPropertyAddConfirmation = false
      }
    },
    /**
     * Sends request to API to remove a property from the group.
     *
     * @param {Object} propertyRemoved - property object
     */
    async removeProperty (propertyRemoved) {
      try {
        this.loading = true
        const properties = findGroup([this.topLevelGroup], this.group.id).properties.filter(property => property.id !== propertyRemoved.id)
        await this.api.groups.partialUpdate(this.group.id, { properties: properties })
        this.$emit('refresh')
      } catch (err) {
        this.loading = false
        const details = err.response ? err.response.data : null
        this.$rfAlert.error(this, err.toString(), details)
      }
    },
    /**
     * Moves an existing subgroup to be a child of this group.
     *
     * @param {Object} subgroup - group object
     * @param {Number} parentId - id of the new parent
     */
    async addSubgroup (subgroup, parentId) {
      try {
        this.loading = true
        await this.api.groups.partialUpdate(subgroup.id, { parent: parentId, properties: subgroup.properties })
        this.$emit('refresh')
      } catch (err) {
        this.loading = false
        const details = err.response ? err.response.data : null
        this.$rfAlert.error(this, err.toString(), details)
      }
    },
    /**
     * Shows confirmation message if subgroup has children or properties
     * otherwise delete the subgroup.
     *
     * @param {Object} subgroup - group object
     */
    deleteSubgroupHandler (subgroup) {
      if (subgroup.children.length || subgroup.properties.length) {
        this.$nextTick(() => {
          this.subgroupToDelete = subgroup
          this.$refs.select.blur()
          this.showDeleteConfirmation = true
        })
      } else {
        this.deleteSubgroup(subgroup)
      }
    },
    /**
     * Sends request to the API to delete the subgroup.
     *
     * @param {Object} subgroup - group object
     */
    async deleteSubgroup (subgroup) {
      try {
        this.loading = true
        const params = {
          confirm_move_children: true
        }
        await this.api.groups.delete(subgroup.id, params)
        this.$emit('refresh')
      } catch (err) {
        this.loading = false
        const details = err.response ? err.response.data : null
        this.$rfAlert.error(this, err.toString(), details)
      } finally {
        this.showDeleteConfirmation = false
      }
    },
    /**
     * Checks if the object is a property or a subgroup.
     * @returns {Boolean}
     */
    isSubgroup (object) {
      return object.hasOwnProperty('properties')
    },
    /**
     * Handler for when the 'Add Sub Group' button is clicked.
     * Closes the dropdown and shows and focuses on the create subgroup input.
     */
    addSubGroupHandler () {
      this.$refs.select.blur()
      this.showNewGroupInput = true
      this.$nextTick(() => {
        this.$refs.newGroupInput.focus()
      })
    },
    /**
     * Calls API to create a Group.
     */
    async createSubgroup () {
      if (!this.newGroupName) {
        this.showNewGroupInput = false
        return
      }
      const group = {
        name: this.newGroupName,
        parent: this.group.id,
        company: this.group.company,
        properties: []
      }
      try {
        this.loading = true
        await this.api.groups.create(group)
        this.$message({
          message: `Sub Group ${group.name} created.`,
          type: 'success',
          duration: 3500
        })
        this.$emit('refresh')
      } catch (err) {
        this.loading = false
        const details = err.response ? err.response.data : null
        this.$rfAlert.error(this, err.toString(), details)
      } finally {
        this.newGroupName = ''
        this.showNewGroupInput = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.group-properties {
  .select-label {
    font-size: 14px;
    margin: 0.5em 0;
  }
  .el-select {
    width: 100%;
    ::v-deep .el-tag {
      display: none;
    }
  }
  .selected-list {
    margin-top: 1em;
    padding: 0;
    li {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #ebeef5;
      padding: 0 0.75em;
      height: 40px;
      box-sizing: border-box;

      &:first-child {
        border-top: 1px solid #ebeef5;
      }

      .list-item-label {
        position: absolute;
        padding-left: 1.5em;
      }

      .el-button {
        margin-left: auto;
        padding: 0;
      }

      &.new-subgroup {
        i {
          margin-right: 10px;
        }
      }
    }
    .slide-fade-enter-active, .slide-fade-leave-active {
      transition: all .3s ease;
    }
    .slide-fade-enter, .slide-fade-leave-to {
      transform: translateX(10px);
      opacity: 0;
    }
    .drawer-confirmation {
      padding-bottom: 1em;
      border-bottom: 1px solid #ebeef5;
      .btn-group {
        text-align: right;
      }
    }
  }
  &.group-properties--invoicing {
    .selected-list .list-item-label {
      display: block;
      padding-left: 0;
    }
  }
}
.select-action {
  padding: 0 20px;
  border-bottom: 1px solid #ebeef5;
}
.option-icon {
  margin-right: 5px;
}
.empty {
  list-style: none;
  padding: 5px 0;
}
</style>
