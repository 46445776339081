import { request } from './index'
import { MissingIdError, MissingPayloadError } from './errors'

const ReportingAPI = {
  list: async () => {
    const response = await request({
      method: 'get',
      url: '/reports/'
    })
    return response.data
  },
  property: {
    analytics: async (id, params = {}) => {
      if (!id) {
        throw new MissingIdError()
      }
      const response = await request({
        method: 'get',
        url: `/reports/properties/${id}/analytics/`,
        params
      })
      return response.data
    },
    ads: async (id, params = {}) => {
      if (!id) {
        throw new MissingIdError()
      }
      const response = await request({
        method: 'get',
        url: `/reports/properties/${id}/ads/`,
        params
      })
      return response.data
    },
    calls: async (id, params = {}) => {
      if (!id) {
        throw new MissingIdError()
      }
      const response = await request({
        method: 'get',
        url: `/reports/properties/${id}/calls/`,
        params
      })
      return response.data
    }
  },
  on_demand: {
    create: async data => {
      if (!data) {
        throw new MissingPayloadError()
      }
      const response = await request({
        method: 'post',
        url: '/reports/on-demand/',
        data: data
      })
      return response.data
    }
  }
}

export default ReportingAPI
