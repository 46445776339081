<template>
  <el-drawer
    :visible="visible"
    v-bind="$attrs"
    @update:visible="$emit('update:visible', $event)"
  >
    <template #title>
      <slot name="title" />
    </template>
    <slot />
  </el-drawer>
</template>

<script>
/**
 * Wrapper for ElementUI drawer with custom styling.
 *
 * Supports all element drawer attributes:
 * https://element.eleme.io/#/en-US/component/drawer#drawer-attributes
 */
export default {
  name: 'Drawer',
  props: {
    visible: {
      type: Boolean,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
.el-drawer__wrapper {
  ::v-deep .el-drawer {
    outline: none;
    .el-drawer__header {
      margin: 0;
      display: block;
      > * {
        margin: 0;
        outline: none;
      }
      .el-drawer__subtitle {
        margin-top: 0.5em;
        font-size: 0.75em;
      }
      .el-drawer__close-btn {
        position: absolute;
        padding: inherit;
        top: 0;
        right: 0;
      }
    }
    .el-drawer__body {
      padding: 20px;
    }
  }
}
</style>
